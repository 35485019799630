import {
  IdentificationType,
  OrderFrequency,
  OrderPaymentFrequency,
  OrderPaymentType,
  OrderPeriodicity,
  OrderServiceProductInstitutionStatus,
  OrderServiceRate,
  PaymentCalendarItemStatus,
  StepType,
  VATPayerOrderTaxChangeType,
} from "Api/Api";
import { ResourceDictionary } from "./Resources";
import { UpcomingOrdersDateRange } from "Components/Dashboard/UpcomingPeriodicOrders";
import { ServicePackageType } from "Components/Orders/Draft/StepFormValidationSchema";

export const CzechResourcesDictionary: ResourceDictionary = {
  Common: {
    AppName: "Placené poradenství",
    Years: "{{count}} let",
    Years_one: "{{count}} rok",
    Years_two: "{{count}} roky",
    Years_few: "{{count}} roky",
    Years_other: "{{count}} let",
    YearsLabel: "let",
    YearsLabel_one: "rok",
    YearsLabel_few: "roky",
    YearsLabel_other: "let",
    Month: "měsíc",
    Months: "{{count}} měsíců",
    Months_one: "{{count}} měsíc",
    Months_two: "{{count}} měsíce",
    Months_few: "{{count}} měsíce",
    Months_other: "{{count}} měsíců",
    Continue: "Pokračovat",
    Step: "KROK {{actual}} Z {{count}}",
    TextCopied: "Text byl zkopírován do schránky.",
    Yes: "Ano",
    No: "Ne",
    Choose: "Vyberte",
    Download: "Stáhnout",
    Downloading: "Stahování",
    UnsupportedFileType: "Nepodporovaný typ souboru",
    Email: "E-mail",
    Password: "Heslo",
    ExampleLogin: "např. antonin.vomacka@email.cz",
    PiecesShort: "{{count, number}} ks",
    PiecesSuffix: "ks",
    Active: "Aktivní",
    Inactive: "Neaktivní",
    Actual: "Aktuální",
    Blocked: "Blokováno",
    Canceled: "Zrušeno",
    Cancel: "Zrušit",
    Close: "Zavřít",
    Confirm: "Potvrdit",
    Ended: "Ukončeno",
    All: "Vše",
    AllStatuses: "Všechny stavy",
    PrivacyPolicyLink: "Zásadách zpracování osobních údajů.",
    Error:
      "Něco se pokazilo. Omlouváme se za komplikace. Zkuste to prosím znovu za chvíli.",
    Save_FirstUppercase: "Uložit",
    Save: "Uložit",
    Reset: "Reset",
    WithTax: "s DPH",
    WithoutTax: "bez DPH",
    Edit: "Upravit",
    Back: "Zpět",
    Delete: "Smazat",
    PreviousStep: "Předchozí krok",
    SaveAndClose: "Uložit a zavřít",
    SaveSuccess: "Uložení proběhlo úspěšně.",
    CompanyNumber: "IČ",
    TaxNumber: "DIČ",
    PersonalNumber: "RČ",
    ProcessedByRepresentative: "Zpracoval{{gender}} {{name}}",
    SendByRepresentative: "Odeslal{{gender}} {{name}}",
    InvoceAfterDueDate: "Faktura po splatnosti",
    NoCommission: "Není provize k proplacení",
  },
  Errors: {
    ApplicationError: {
      Title: "Nastala chyba",
      Home: "Domů",
    },
    Error404: {
      Subtitle: "Stránka nebyla nalezena",
      Home: "Domů",
    },
    Error403: {
      Subtitle: "K zobrazení této stránky nemáte přístupová práva",
    },
    Error500: "Při zpracování požadavku nastala chyba.",
  },
  SignIn: {
    Error: {
      General: "Nastala chyba",
      InvalidLogin: "Špatné heslo nebo e-mail",
      InvalidCredentials: "Špatné heslo nebo e-mail",
      ApplicationVerification: "Špatné heslo nebo e-mail",
      PhoneNumberNotFound: "Telefonní číslo nebylo nalezeno",
      SmsSendingFailed: "Zlyhalo posílání SMS ",
      InvalidToken: "Navalidní token",
      AccountNotFound: "Špatné heslo nebo e-mail",
    },
  },
  SignUp: {
    Error: {
      General: "Nastala chyba",
      UserExists: "Účet již existuje",
      WeakPassword:
        "Heslo musí mít alespoň 8 znaků, obsahovat jedno malé, jedno velké písmeno a jedno číslo",
    },
  },
  Validation: {
    Required: "Vyplňte pole",
    InvalidEmail: "E-mail je nevalidní",
    AlphabetsOnly: "Pro toto pole jsou povoleny pouze písmenka",
    DigitsOnly: "Může obsahovat pouze číslice",
    InvalidPhone: "Telefonní číslo je nevalidní",
    InvalidFormat: "Neplatný formát",
    InvalidBankAccount: "Neplatné číslo účtu",
    InvalidPersonalIdentificationNumber: "Neplatné rodné číslo",
    InvalidTaxNumber: "Neplatné DIČ",
    InvalidCompanyNumber: "Neplatné IČO",
    IsTrue: "Musí být zaškrtnuto",
    Number: {
      Integer: "Musí být celé číslo",
      Min: "Musí být větší nebo rovno {{count}}",
      MoreThen: "Musí být větší než {{count}}",
    },
    String: {
      Min: "Musí být alespoň {{count}} znaků dlouhé.",
      Max: "Nesmí být delší než {{count}} znaků.",
      StrictLength: "Musí obsahovat {{count}} znaků",
      InvalidFormat: "Neplatný formát",
    },
    Array: {
      Min: "Musí obsahovat alespoň {{count}} položku.",
      Min_one: "Musí být vybrána alespoň {{count}} položka.",
      Min_few: "Musí být vybrány alespoň {{count}} položky.",
      Min_other: "Musí být vybráno alespoň {{count}} položek.",
    },
    FileTooBig: "Soubor je příliš velký. Maximální velikost je {{maxSize}} MB.",
  },
  Transactions: {
    Type: {
      Buy: "Nákup",
      Sell: "Prodej",
      Withdrawal: "Výběr",
      LeadReward: "Odměna za doporučení",
    },
    Status: {
      Completed: "Provedeno",
      PendingTrade: "Zpracovává se",
      WithdrawRequested: "Probíhá výběr",
      Withdrawn: "Provedeno",
    },
    PartyType: {
      LegalEntity: "Právnická osoba",
      NaturalPerson: "Fyzická osoba",
      SelfEmployed: "OSVČ",
    },
    EnterpriseMode: {
      Strict: "HSP",
      Free: "Poradce",
    },
    EnterpriseProductInvoiceForType: {
      User: "Poradce",
      Enterprise: "HSP",
    },
    EnterpriseEmailCommunicationMode: {
      OnlySupplierCompany: "Pouze HSP",
      SupplierCompanyAndUser: "Poradce i HSP",
    },
    PrepaidOrderReminderForPaymentCalendarItemsStatus: {
      Active: "Aktivní",
      Inactive: "Neaktivní",
    },
    PriceTaxTypeName: {
      WithoutTax: "Ceny bez DPH",
      WithTax: "Ceny s DPH",
    },
  },
  SetPassword: {
    Error: {
      General: "Nastala chyba.",
      TokenNotFound: "Token je navalidní",
      WeakPassword:
        "Heslo musí mít alespoň 8 znaků, obsahovat jedno malé, jedno velké písmeno a jedno číslo",
    },
  },
  Dashboard: {
    PageName: "Dashboard",
    Title: "Dobrý den,",
    Subtitle: "Zde máte přehled aktuálního stavu",
    Statistics: {
      Clients: "Počet klientů",
      Orders: "Počet aktivních objednávek",
      ExpectedCommission: "Očekávaná měsíční provize",
      ExpectedCommissionTooltip:
        "Provize aktivních schválených objednávek klientem, které dosud nebyly vyplaceny za tento měsíc.",
      Leads: "Počet potenciálních klientů",
      PaidCommission: "Proplacené provize",
      PaidCommissionTooltip:
        "Provize aktivních schválených objednávek klientem, které byly proplaceny.",
    },
    OrdersInProgress: {
      Title: "Objednávky k řešení",
    },
    Anniversary: {
      Title: "Blížící se výročí",
      Tooltip:
        "Objednávky, které měly nastavené opakování a blíží se jejich výročí. U těchto smluv je potřeba vystavit novou objednávku.",
    },
  },
  Orders: {
    PageName: "Přehled objednávek",
    List: {
      Title: "Přehled objednávek",
      Query: "Vyhledat klienta, poradce, číslo objednávky...",
      DateRangeTooltip: "Datum vytvoření objednávky",
    },
    Detail: {
      CreateTitle: "Nová objednávka",
      DraftTitle: "Objednávka číslo #{{number}}",
      DetailTitle: "Objednávka číslo",
      NotFound: "Objednávka nebyla nalezena",
      Provider: {
        Label: "Poskytovatel",
        Title: "Poskytovatel",
      },
      Icons: {
        ISDOC: "Faktura ve formátu ISDOC",
        ISDOCNotAvailable: "Faktura ve formátu ISDOC není k dispozici",
        ISDOCNotAvailableZeroPrice:
          "Hodnota objednávky je 0 Kč, proto nebyla vygenerována faktura a ve formátu ISDOC není k dispozici",
        Pdf: "Faktura ve formátu PDF",
        PdfNotAvailable: "Faktura ve formátu PDF není k dispozici",
        PdfNotAvailableZeroPrice:
          "Hodnota objednávky je 0 Kč, proto nebyla vygenerována faktura a ve formátu PDF není k dispozici",
      },
      Client: {
        Status: {
          New: "Nový klient",
          Existing: "Stávající klient",
        },
        Search: "Vyhledat klienta",
        Load: "Načíst z ARES",
        Title: "Klient",
        Type: "Typ klienta",
        City: "Město",
        Email: "E-mail",
        FirstName: "Jméno",
        LastName: "Příjmení",
        CompanyName: "Název",
        CompanyNumber: "IČO",
        IsVatPayer: "Plátce DPH",
        TaxNumber: "DIČ",
        PhoneNumber: "Telefon",
        Street: "Ulice",
        StreetNumber: "Č.p. / č.o.",
        PostalCode: "PSČ",
      },
      Periodicity: {
        Title: {
          Single: "Připomenutí další objednávky",
          Periodic: "Připomenutí následné objednávky",
        },
        Disclaimer:
          "Dne {{date}} budete upozorněni k vystavení další objednávky pro klienta.",
        Label: "Připomenout vystavení další objednávky",
        Frequency: {
          Label: "Doba upozornění",
          Tooltip:
            "Vyberte, jak dlouho před koncem období chcete být upozorněni",
        },
      },
      Services: {
        Title: "Služby",
        ServiceRate: "Cena služby",
        HourlyCharge: "Hodinová sazba",
        Offers: "Nabídka služeb",
        NoServiceVariants: "Pro zvolené parametry není dostupná žádná služba",
        NoServiceVariantsAndPackages:
          "Pro zvolené parametry není dostupná žádná služba ani balíček služeb",
        Variant: "Varianta",
        TotalPrice: {
          Single: {
            FixedRate: "Cena celkem",
            HourRate: "Předpokládaná cena celkem",
          },
          Periodic: {
            FixedRate: "Měsíční cena",
            HourRate: "Předpokládaná měsíční cena",
          },
        },
      },
      ClientConsents: {
        IsClientPersonalDataProcessingConsent:
          "Založit klienta v myDOCK (bude odeslán modrý souhlas - GDPR)",
        IsClientElectronicCommunicationConsent:
          "Odeslat klientovi souhlas s elektronickou komunikací (zlatý souhlas)",
      },
      Note: {
        Label: "Poznámka k objednávce",
        Title: "Poznámka pro klienta",
        Placeholder:
          "Zde můžete klientovi přidat poznámku, která se zobrazí klientovi u objednávky",
      },
      Summary: {
        Title: "Rekapitulace objednávky",
        AreTermsAccepted: {
          Label:
            "Potvrzuji správnost objednávky a souhlasím s odesláním klientovi ke schválení.",
          Validation:
            "Musíte potvrdit správnost objednávky a souhlas se zasláním klientovi",
        },
        Process: "Odeslat objednávku klientovi",
        OrderNumber: "Číslo objednávky #{{number}}",
        CompanyNumber: "IČO: {{companyNumber}}",
        Email: "E-mail: {{email}}",
        Iban: "Bankovní účet: {{iban}}",
        ApprovalTerms: "Obchodní podmínky",
        GDPR: "Podmínky zpracování osobních údajů",
        Phone: "Tel.: {{phone}}",
        VariableSymbol: "Variabilní symbol: {{symbol}}",
        TaxNumber: "DIČ: {{taxNumber}}",
        Services: {
          Title: "Objednané služby",
          Price: {
            Single: "Cena celkem",
            Periodic: "Cena",
          },
          PricePerFrequency: {
            Periodic: "Cena za období",
          },
          Periodicity: {
            Title: "Období poskytování služeb",
            Dates: "Od {{from}} do {{to}} ({{months}})",
            DateWithoutEnd: "Od {{from}} na neurčito",
          },
          ApprovalDate: "Objednávka potvrzena",
          ApprovalPaymentDate: "Objednávka označena za zaplacenou",
          RejectionDate: "Objednávka zamítnuta",
          PackageIncludeText: "Balíček zahrnuje následující služby:",
        },
        Note: "Poznámka pro klienta",
      },
      SavedSuccess: "Objednávka úspěšně uložena do návrhu",
      Payment: {
        Title: "Platba",
        Label: "Frekvence platby a období poskytování služby",
        RadioButtons: {
          PeriodPostfix: {
            [OrderPaymentFrequency.Single]: "",
            [OrderPaymentFrequency.Monthly]: "měsíc",
            [OrderPaymentFrequency.Quarterly]: "kvartál",
            [OrderPaymentFrequency.Semiannually]: "pololetí",
            [OrderPaymentFrequency.Yearly]: "rok",
          },
          Tooltip: {
            [OrderPaymentFrequency.Single]:
              "Celá částka bude fakturována najednou po dodání služby",
            [OrderPaymentFrequency.Monthly]:
              "Tato částka bude automaticky fakturována zpětně za každý měsíc po určité období.",
            [OrderPaymentFrequency.Quarterly]:
              "Tato částka bude automaticky fakturována zpětně za každý kvartál po určité období.",
            [OrderPaymentFrequency.Semiannually]:
              "Tato částka bude automaticky fakturována zpětně za každé pololetí po určité období.",
            [OrderPaymentFrequency.Yearly]:
              "Tato částka bude automaticky fakturována zpětně za každý rok po určité období.",
          },
        },
        PaymentFrequencyDate: {
          From: {
            Label: "Období od",
          },
          To: {
            Label: "Období do",
          },
        },
        isIndefiniteEnd: {
          Label: "Na dobu neurčitou",
        },
        PaymentType: {
          Label: "Typ platby",
        },
        Periodicity: {
          Label: "Frekvence platby",
          Tooltip: "Vyberte, jak často bude klient platit za služby",
        },
        ServicePackage: {
          Type: "Poskytnutí",
        },
        OverallPrice: "Celková cena",
        MonthPrice: "Měsíční platba",
      },
      DraftForm: {
        Delete: {
          Button: "Smazat návrh",
          Title: "Smazání návrhu objednávky",
          Confirmation: "Opravdu chcete smazat tento návrh objednávky?",
        },
      },
    },
    Completed: {
      Title: "Opakování objednávky",
      Description:
        "Tato událost má nastaveno opakování na {{date}}. Můžete vytvořit navazující objednávku nebo opakování zrušit.",
      CompleteOrder: "Uzavřít objednávku",
      RepeatOrder: "Vytvořit další objednávku",
      CancelRepetitionDialog: {
        OpenDialogButton: "Zrušit opakování",
        Title: "Zrušit opakování",
        Text: "Opravdu chcete zrušit opakování?",
      },
      SetRepetitionDialog: {
        OpenDialogButton: "Nastavit opakování",
        Title: "Nastavit opakování",
      },
    },
    Client: {
      Title: "Odběratel",
      Representative: "Zastoupen",
    },
    Supplier: {
      Title: "Poskytovatel",
    },
    Workflow: {
      Services: {
        Title: "Objednané služby",
        TotalPrice: {
          Single: "Cena celkem",
          Periodic: "Cena",
        },
        Delete: {
          Title: "Smazat službu?",
          Text: "Opravdu chcete smazat službu {{name}}?",
          Disabled: {
            TooLate:
              "Položku není možné odstranit, protože objednávka byla již schválena klientem.",
            LastService:
              "Nelze smazat poslední službu v objednávce. Smažte celou objednávku.",
            IsReadOnly: "Nemáte oprávnění smazat službu",
          },
        },
        EucsService: {
          State: "Stav smlouvy:",
          Documents: "Dokumentace:",
          Loading: "Načítají se údaje z EUCS",
          ErrorLoading:
            "Nepovedlo se načíst údaje z EUCS, zkuste prosím později.",
          Status: {
            [OrderServiceProductInstitutionStatus.Created]: "",
            [OrderServiceProductInstitutionStatus.NotCreatedBecauseTest]:
              "Smlouva nebyla v EUCS vytvořena, protože je v konfiguraci nastaveno testovací prostředí.",
            [OrderServiceProductInstitutionStatus.Canceled]: "",
            [OrderServiceProductInstitutionStatus.CreationError]:
              "Nepovedlo se uzavřít smlouvu v EUCS, vytvoření můžete opakovat tlačítkem pro uzavření níže.",
            [OrderServiceProductInstitutionStatus.WaitToCreate]:
              "Smlouva se v EUCS uzavře, až klient objednávku schválí.",
          },
          Cancel: {
            Tooltip: "Zrušit smlouvu v EUCS",
            Title: "Zrušit smlouvu v EUCS?",
            Text: "Opravdu chcete zrušit smlouvu v EUCS?",
            Disabled: {
              Status: {
                [OrderServiceProductInstitutionStatus.Created]: "",
                [OrderServiceProductInstitutionStatus.NotCreatedBecauseTest]:
                  "",
                [OrderServiceProductInstitutionStatus.Canceled]:
                  "Smlouvu v EUCS nelze zrušit, jelikož už byla zrušena.",
                [OrderServiceProductInstitutionStatus.CreationError]:
                  "Smlouvu v EUCS nelze zrušit, jelikož se ji nepovedlo vytvořit",
                [OrderServiceProductInstitutionStatus.WaitToCreate]:
                  "Nelze zrušit smlouvu v EUCS, jelikož ještě nebyla vytvořena v EUCS, vytvoří se až klient schválí objednávku.",
              },
              IsReadOnly: "Nemáte oprávnění zrušit smlouvu v EUCS",
            },
          },
          Create: {
            Text: "Zkusit znovu",
            Disabled: {
              IsReadOnly: "Nemáte oprávnění vytvořit smlouvu v EUCS",
            },
          },
        },
      },
      Workflow: {
        Title: "Workflow",
        SeenByClientStep: {
          Text: "Klient neschválil objednávku více než 14 dní. Chcete mu poslat e-mailem připomínku?",
          Send: "Poslat připomínku",
        },
        ClientAssignmentStep: {
          Send: "Znovu odeslat email klientovi",
          WasSent: "Odesláno klientovi",
          Text: "Nedorazil klientovi email nebo jej smazal, můžete jej poslat znovu.",
        },
        ProcessingServicesStep: {
          Description:
            "Jakmile budou služby zpracovány, potvrďte dodání jednotlivých služeb.",
          Complete: "Potvrdit dodání služby",
          DateProcessed: "Dodáno {{date}}",
          Confirm: {
            Title: "Potvrzení dodání služby",
            Content: "Opravdu chcete potvrdit dodání služby?",
          },
          Revocation: {
            True: "Klient se vzdal 14 denní lhůty na odstoupení od smlouvy. Můžete mu bezpečně poskytnout služby.",
            False:
              "Klient se nevzdal 14 denní lhůty na odstoupení od smlouvy. Jestliže klientovi poskytnete služby, může od smlouvy odstoupit.",
          },
        },
        ClientInvoiceIssuanceStep: {
          Description:
            "Všechny služby byly splněny, pošlete klientovi fakturu k úhradě služeb.",
          Complete: "Poslat fakturu",
          ZeroPrice:
            "Hodnota objednávky je 0 Kč, proto nebyla vygenerována faktura.",
        },
        ClientInvoicePaymentStep: {
          ZeroPrice:
            "Hodnota objednávky je 0 Kč, proto je automaticky označená za zaplacenou",
          Pay: "Označit za zaplacenou",
          CancelInvoice: "Faktura byla označena jako zrušená {{date}}",
          Reminder: {
            LastSent: "Poslední upomínka poslána {{date}}",
            Send: "Poslat upomínku klientovi",
            WasSent: "Upomínka odeslána",
          },
          Automation: {
            IsEnabled:
              "Máte zapnuté automatické párování plateb. Faktura bude automaticky označena za zaplacenou, jakmile se platba objeví na účtu.",
            IsDisabled: {
              Part1:
                "Jelikož není aktivní automatické párování, označte fakturu za zaplacenou, jakmile Vám klient pošle peníze na účet.",
              Part2: "Nastavte si automatické párování v ",
              Link: "Nastavení",
              ContactAdmin:
                "Pro aktivaci kontaktujte Vašeho administrátora HSP.",
            },
          },
        },
        ClientPrepaidPaymentApprovalStep: {
          Pay: "Označit objednávku za zaplacenou",
          ClientApproveInfo:
            "Klient objednávku potvrdil {{date}}, ale ještě ji nezaplatil.",
          Automation: {
            IsEnabled:
              "Máte zapnuté automatické párování plateb. Objednávka bude automaticky označena za zaplacenou, jakmile se platba objeví na účtu.",
            IsDisabled: {
              Part1:
                "Jelikož není aktivní automatické párování, označte objednávku za zaplacenou, jakmile Vám klient pošle peníze na účet.",
              Part2: "Nastavte si automatické párování v ",
              Link: "Nastavení",
              ContactAdmin:
                "Pro aktivaci kontaktujte Vašeho administrátora HSP.",
            },
          },
        },
        EnterpriseInvoiceIssuanceAndPaymentStep: {
          Description: "Počkejte na vyplacení provize od HSP.",
          CompletedZeroPrice: "Není žádná provize k proplacení",
          Completed:
            "Faktura pro HSP byla vystavena. Provize bude proplacena do {{date}}",
        },
        RecurringPaymentStep: {
          Description:
            "Faktury budou automaticky vystavovány a zasílány klientovi e-mailem.",
          Header: {
            InvoiceDate: "Datum fakturace",
            Price: "Částka",
            Commission: "Provize",
            Status: "Stav",
            Actions: "Akce",
          },
          Cancel: {
            Title: "Zrušit tuto fakturaci?",
            Text: "Zrušení tohoto termínu fakturace nemá vliv na ostatní termíny.",
          },
          Pay: {
            Title: "Označit za zaplacenou",
            Text: "Opravdu chcete označit tuto fakturu za zaplacenou?",
          },
          SendAndPay: {
            Title:
              "Označit položku za zaplacenou a vytvořit fakturu, která se odešle klientovi",
            Text: "Opravdu chcete označit položku za zaplacenou a vytvořit fakturu, která se odešle klientovi?",
          },
          Remind: {
            Title: "Poslat klientovi upomínku",
            Text: "Opravdu chcete poslat klientovi upomínku?",
            Success: "Upomínka byla úspěšně odeslána.",
          },
          CancelInvoice: {
            Status: "Faktura stornována {{date}}",
            Title: "Stornovat tuto fakturu?",
            Text: "Opravdu chcete stornovat? Tento krok je nevratný. Nezapomeňte fakturu zavést do vašeho účetnictví a následně ji stornovat i tam, aby nedošlo k přerušení číselné řady. ",
          },
          WaitingForCommission: "Čeká na proplacení provize",
          ZeroPrice: "Žádna provize k proplacení",
          ReminderSentStatus: "Upomínka odeslána",
          Previous: {
            Show: "Zobrazit předchozí",
            Hide: "Skrýt předchozí",
          },
          Next: {
            Show: "Zobrazit další",
            Hide: "Skrýt další",
          },
        },
      },
    },
    DeleteConfirmDialog: {
      Title: "Smazání objednávky",
      Text: "Opravdu chcete smazat tuto objednávku?",
      Tooltip: {
        Enabled: "Smazat objednávku",
        Disabled: "Objednávku v tomto stavu již nelze smazat",
        IsReadOnly: "Nemáte oprávnění smazat objednávku",
      },
    },
    CancelInvoiceConfirmDialog: {
      Title: "Stornování faktury?",
      Text: "Opravdu chcete stornovat? Tento krok je nevratný. Nezapomeňte fakturu zavést do vašeho účetnictví a následně ji stornovat i tam, aby nedošlo k přerušení číselné řady.",
      Tooltip: {
        Enabled: "Storno faktury",
        Disabled: "Fakturu v tomto stavu nelze stornovat",
        IsReadOnly: "Nemáte oprávnění stornovat fakturu",
        IsZeroPrice:
          "Hodnota objednávky je 0 Kč, proto nebyla vygenerována faktura a tedy nelze stornovat fakturu",
      },
    },
    EarlyDoneOrderRecurringPaymentDialog: {
      Title: "Předčasné ukončení objednávky",
      Text: "Opravdu chcete předčasně ukončit tuto objednávku?",
      Tooltip: {
        Enabled: "Předčasně ukončit objednávku",
        Disabled:
          "Některé položky z kalendáře jsou ve stavu 'Odesláno klientovi', nelze tedy objednávku předčasně ukončit",
        IsReadOnly: "Nemáte oprávnění předčasně ukončit objednávku",
      },
    },
    CancelInvoiceForClientByOrderWaning: {
      Title: "Faktura byla stornována",
      Text: "Faktura klientovi byla stornována dne {{date}}",
    },
  },
  Settings: {
    PageName: "Nastavení",
    Enterprise: {
      Title: "HSP",
      Url: "enterprise",
      Mode: {
        Title: "Režim poskytování služeb",
        Description: "Nastavte, kdo je poskytovatelem služeb",
        Tooltip: {
          Text: "Nastavte, kdo je poskytovatelem služby.",
          Strict:
            "Služba je poskytována jménem HSP, poradce nemůže služby upravovat, za poradce je automaticky vystavována faktura na provize.",
          Free: "Služba je poskytnuta jménem poradce, poradce může nastavovat služby individuálně, poradce určuje ceny služeb.",
        },
        Confirm: {
          Title: "Opravdu chcete změnit režim poskytování služeb?",
          Text: "Touto změnou dojde k smazání nastavení služeb a fakturačních údajů u poradců.",
        },
      },
      Color: {
        Title: "Barevné schéma",
        Description: "Nastavte, barevné schéma modulu Placeného poradenství",
        Tooltip: "Všichni Vaši poradci budou mít následující barevné schéma.",
      },
      LogoBase64: {
        Title: "Logo společnosti",
        Description:
          "Nastavte si logo Vaší společnosti, které se bude zobrazovat v hlavičce aplikace",
        Tooltip:
          "Vaše logo se bude zobrazovat v hlavičce aplikace i v hlavičkách objednávek a e-mailů.",
        Label: "Nahrát logo",
        Placeholder: "Nahrát obrázek",
      },
      EmailMode: {
        Title: "Nastavení komunikace",
        Description: "Nastavte, čím jménem mají chodit emaily.",
        OnlySupplier: "Pouze HSP",
        SupplierAndUser: "Poradce i HSP",
        Tooltip: {
          Title:
            "Nastavte, jak budou vypadat emaily a objednávky, které jdou na klienta.",
          OnlySupplier:
            "V emailové komunikaci nebude nikde zmíněný poradce. Podpis v emailu i informace na objednávce budou obsahovat pouze název HSP. Případná odpověď na email půjde přímo na HSP.",
          SupplierAndUser:
            "V emailové komunikaci bude podepsán Poradce i HSP, na objednávce bude uvedeno i jméno a příjmení poradce. Případná odpověď na email půjde přímo  na poradce.",
          Example: "Příklad",
          ExampleNameUser: "Petr Novák",
          ExampleNameSupplier: "První HSP",
        },
      },
      EucsMode: {
        Title: "Fakturace EUCS",
        Description:
          "Vyberte, kdo má smlouvu s EUCS a komu EUCS na konci měsíce fakturuje vytvořené smlouvy",
        CompanyNumber: "IČO pro fakturaci EUCS",
        InvoiceForType: {
          HSP: "HSP",
          User: "Poradce",
        },
      },
      EucsStats: {
        Title: "Statistiky EUCS",
        Description: "Počty aktivních smluv",
      },
      PrepaidOrderReminderForPaymentCalendarItemsStatus: {
        Title: "Emailová komunikace na klienta",
        Description:
          "Nastavte, zda má klientovi chodit upozornění na blížící se termín platby nebo vystavená faktura emailem.",
        Tooltip: {
          Text: "U předplacených pravidelných objednávek, pošle upomínkový email klientovi 3 dny před začátkem dalšího platebního období, aby jej nezapomněl uhradit. Zároveň, pokud je komunikace aktivní, odesílá se faktura za zaplacené služby klientovi do emailu. V případě, že je komunikace neaktivní, má klient dostupné faktury v odkazu s objednávkou.",
        },
      },
      Save: {
        Success: "Nastavení bylo uloženo",
      },
    },
    Services: {
      Title: "Služby",
      Url: "services",
      ProvidedServices: {
        Title: "Poskytované služby",
        Description:
          "Nastavte, které služby můžou poradci nabízet a jejich ceny.",
        Tooltip:
          "Nastavte, které služby můžou vaši poradci nabízet a jaká je jejich cena",
      },
      HourRateService: {
        Title: "Hodinová sazba poradců",
        Description:
          "Nastavte, za jakou hodinovou sazbu mohou poradci nabízet služby.",
        Tooltip: {
          Description: "Nastavte, kdo je poskytovatelem služby.",
          StrictModeHeader: "HSP",
          StrictModeDescription:
            "Služba je poskytována jménem HSP, poradce nemůže služby upravovat.",
          FreeModeHeader: "Poradce",
          FreeModeDescription:
            "Služba je poskytnuta jménem poradce, poradce může nastavovat služby individuálně, poradce určuje ceny služeb.",
        },
      },
      ServiceSettings: {
        Title: "Změna ceny",
        Description:
          "Nastavte, zda poradce může upravit cenu služby nebo balíčku.",
        Tooltip:
          "Pokud tuto funkci povolíte, poradce bude mít možnost libovolně nastavit cenu služby nebo balíčku.",
        CanUserSetPrice: "Poradce může nastavovat cenu individuálně",
        MaximalPriceRangeRate: "Maximální úprava ceny",
      },
      VariantIsRequired: "Sazba není vybrána",
    },
    Commission: {
      Title: "Provize",
      Url: "commission",
      Tooltip:
        "V této sekci můžete určit, kolik % z každé poskytnuté služby zůstane HSP a zbytek dostane poradce.",
      Description: "Nastavení provizí za jednotlivá období.",
      AdvisorCommissionRate: "Podíl pro poradce",
      EnterpriseCommissionRate: "Podíl pro HSP",
      TotalCommission: "Celková provize není 100%",
      EnterpriseModeFree:
        "Poskytování služeb je v režimu poradce. Provize se nevyplácí.",
    },
    Companies: {
      Title: "Fakturace",
      Url: "companies",
    },
    Invoices: {
      Title: "Vydané faktury",
      Url: "invoices",
      Invoice: "Faktura",
      Company: {
        Title: "Fakturační údaje",
        Description:
          "Nastavte fakturační údaje, které budou na automaticky vystavených fakturách vůči HSP",
        Tooltip:
          "Uvedené informace budou uvedeny na automaticky vygenerované faktuře",
        Add: "+ Přidat fakturační údaje",
      },
      InvoicesList: {
        CustomerFormattedName: "Odběratel",
        Title: "Vydané faktury",
        Description: "Automaticky vystavené faktury pro HSP Vašim jménem.",
        Tooltip: "Přehled vydaných faktur vašemu HSP",
        Pdf: "Stáhnout fakturu ve formátu PDF",
        ISDOC: "Stáhnout fakturu ve formátu ISDOC",
        Number: "Číslo faktury",
        InvoiceDate: "Vystavena",
        PriceWithoutTax: "Částka",
        PriceWithTax: "Částka s DPH",
        DownloadAll: {
          Button: "Stáhnout vše",
          File: "Vydané faktury",
        },
      },
    },
    Cutoff: {
      Title: "Uzávěrka",
      Url: "cutoff",
      New: {
        Title: "Uzávěrka",
        Tooltip:
          "Zaplacené služby jsou rozděleny dle nastavení v sekci Provize. Po spuštění uzávěrky jsou vygenerovány faktury od poradců. Dále je připraven rozpad provizí a platební příkaz.",
        Run: "Spustit uzávěrku",
        EmptyOrders: "V systému nejsou žádné objednávky k uzávěrce.",
        Success: "Uzávěrka byla úspěšně spuštěna.",
        NotFound:
          "V daném období uzávěrky neexistuje zaplacená objednávka, uzávěrku tedy nelze spustit, jelikož by byla prázdná.",
        Eucs: {
          Price: {
            Label: "Částka Garance EUCS",
            Tooltip:
              "Nastavte částku, která má být odečtena před spuštěním uzávěrky z každé objednávky, která obsahovala Garanci EUCS. Tato částka Vám zůstane na účtě, abyste ji následně mohli zaplatit EUCS.",
          },
        },
      },
      Create: {
        Validation: {
          General: "Nastala chyba",
          ForThisSupplierCutoffAlreadyRunning:
            "Pro tohoto poskytovatele nyní již probíhá uzávěrka, počkejte chvíli",
          DateFromIsInConflictWithPreviousCutoffDateTo:
            "Období od je v konlfiktu s předchozí uzávěrkou, zřejmě někdo jiný nyní vytvořil uzávěrku pro zvoleného poskytovatele, prosím obnovte stránku ať uzávěrku vidíte",
        },
      },
      Dialog: {
        Title: "Spustit uzávěrku",
        Label: "Nastavte období uzávěrky",
        Tooltip:
          "Uzávěrky na sebe musí navazovat, proto je počátek období nastaven automaticky.",
        DateFrom: "Období od",
        DateTo: "do",
        IsConfirmed:
          "Potvrzuji spuštění uzávěrky, která vygeneruje rozpad provizí a dále za poradce vygeneruje vystavené faktury proti HSP",
        Draft: "Náhled uzávěrky",
        Warning: {
          T1: "Po spuštění uzávěrky již není možná žádná úprava! Pro náhled využijte funkci “",
          T2: "Náhled uzávěrky",
          T3: "”, která vygeneruje PDF s rozpadem provizí, pomocí kterého můžete provést kontrolu před finálním spuštěním uzávěrky.",
        },
      },
      List: {
        Title: "Historické uzávěrky",
        Tooltip:
          "Zaplacené služby jsou rozděleny dle nastavení v sekci Provize. Po spuštění uzávěrky jsou vygenerovány faktury od poradců. Dále je připraven rozpad provizí a platební příkaz.",
        Description: "Již proběhlé uzávěrky a vygenerované podklady.",
        DateFrom: "Období uzávěrky",
        DateProcessed: "Spuštěno",
        DownloadZipInvoiceForCompanyByUser:
          "Stáhnout přijaté faktury od poradců",
        DownloadZipInvoiceForClientByOrder: "Stáhnout faktury z objednávek",
        DownloadPdf: "Stáhnout rozpad provizí",
        GeneratePayments:
          "Vygenerovat platební příkaz (Dostupné pouze při propojení s FIO)",
        GeneratePaymentsSuccess: "Platební příkaz byl vygenerován",
        CompanyName: "Společnost",
      },
    },
    ServicePackages: {
      Title: "Balíčky služeb",
      Description:
        "Nastavte, které balíčky služeb mohou poradci nabízet a jejich ceny.",
      Tooltip:
        "Tato funkce umožňuje spojit více samostatných služeb do jediného balíčku, který je pak nabízen jako jeden celek.",
      Url: "service-packages",
      CreateButton: "+ Přidat balíček",
      PackageServiceSettings: {
        Title: "Nastavení balíčků",
        Description:
          "Nastavte, minimální a maximální cenu za balíček, který můžou vaši poradci sami vytvořit.",
        Tooltip:
          "Nastavte si, zda vaši poradci mohou vytvářet vlastní balíčky a případně cenová omezení. Pokud poradcům vytváření balíčků nedovolíte, budou mít dostupné pouze ty, které vytvoříte vy.",
        Single: {
          MinimalPriceLabel: "Minimální cena jednorázového bez DPH",
          MaximalPriceLabel: "Maximální cena jednorázového bez DPH",
        },
        Monthly: {
          MinimalPriceLabel: "Minimální cena měsíčního bez DPH",
          MaximalPriceLabel: "Maximální cena měsíčního bez DPH",
        },
        IsCreatePackageByAdvisorLabel: "Povolit vytváření balíčků poradcům",
        NoLimitsLabel: "Pokud nechcete ceny omezovat, položku nevyplňujte.",
        Validation: {
          MinMustBeLessThanMax: "Minimální cena musí být menší než maximální",
          MaxMustBeGreaterThanMin:
            "Maximální cena musí být větší než minimální",
        },
      },
      List: {
        Services: "Služby",
        Variants: "Sazby",
        Delete: {
          ModalTitle: "Smazání balíčku",
          ModalContent: "Opravdu chcete smazat tento balíček?",
          SuccessToast: "Balíček byl smazán",
        },
      },
      Detail: {
        Title: {
          New: "Nový balíček služeb",
          Existing: "{{name}} - editace balíčku služeb ",
        },
        Form: {
          Title: "Základní informace",
          FormDescription: "Nastavte základní informace o balíčku.",
          Name: {
            Label: "Název",
            Placeholder: "Název balíčku",
          },
          Description: {
            Label: "Popis",
            Placeholder: "Popis balíčku",
          },
          Services: {
            Title: "Služby",
            Description:
              "Vyberte služby, které chcete v tomto balíčku poskytovat.",
            Validation: "Musíte vybrat alespoň jednu službu",
          },
          PriceTaxType: {
            Label: "Zadávání cen",
            Tooltip:
              "Nastavte si, zda chcete zadávat ceny bez DPH a DPH se dopočítá, nebo nastavíte cenu s DPH a dopočítá se cena bez DPH. Na objednávce je zobrazena cena s DPH pouze v případě, že je poskytovatel plátce DPH. Pokud poskytovatel plátce DPH není, pracuje se s cenou bez DPH.",
          },
          Variants: {
            Title: "Sazby",
            Description:
              "Vyberte si v jakých pásmech chcete tento balíček poskytovat a za jaké ceny.",
            Validation: {
              AtLeastOneSelected: "Musíte vybrat alespoň jednu sazbu",
              Range: {
                Min: "Minimální cena je {{min}}",
                Max: "Maximální cena je {{max}}",
              },
            },
            PriceWithoutTax: {
              Label: "Cena bez DPH",
              Placeholder: "Cena bez DPH",
            },
          },
          Save: "Uložit",
          Delete: {
            Button: "Smazat",
            Title: "Smazání balíčku",
            Confirmation: "Opravdu chcete smazat tento balíček?",
          },
        },
      },
    },
  },
  Companies: {
    List: {
      Title: "Fakturační údaje",
      Description:
        "Seznam subjektů, na které je možné vystavovat faktury klientům.",
      Tooltip:
        "Z uvedených subjektů je možné vybírat při vytvoření objednávky.",
      Add: "+ Přidat společnost",
      CompanyNumber: "IČO",
      TaxNumber: "DIČ",
      IsDefault: "DEFAULTNÍ",
    },
    Detail: {
      Title: {
        New: "Nové fakturační údaje",
        Edit: "Fakturační údaje",
      },
      BasicInformation: {
        Title: "Základní informace",
        Description:
          "Nastavte fakturační údaje, které budou na objednávkách a fakturách.",
        Tooltip: "Zadejte IČO a načtěte informace ze systému ARES",
      },
      OtherInformation: {
        Title: "Ostatní",
        Tooltip: "Další informace",
        IsDefault: "Defaultní fakturační údaje",
        CanBeSelectedToOrder: "Skrýt z nabídky poskytovatelů v objednávce",
        CanBeSelectedToOrderTooltip:
          "Pokud je tato možnost zaškrtnuta, v objednávce není možné zvolit tohoto poskytovatele. Např. pokud nechcete, aby na poskytovatele vznikaly další objednávky.",
        InvoiceNumberSeries: "Číslo první faktury",
        InvoiceNumberSeriesDescription:
          "Nastavte první číslo číselné řady, která bude použita na čísla vygenerovaných faktur.",
        InvoiceNumberSeriesWarning:
          "Nevyužívejte čísla této řady pro ruční vytváření faktur, aby nedošlo k dvojitému použití stejného čísla.",
        InvoiceNumberSeriesPlaceholder: "XXXXXXX",
      },
      Logo: {
        Title: "Logo společnosti",
        Tooltip:
          "Vaše logo se bude zobrazovat v hlavičce aplikace i v hlavičkách objednávek a e-mailů.",
        Description:
          "Nastavte si logo Vaší společnosti, které se bude zobrazovat v hlavičce aplikace",
        Label: "Nahrát logo",
        Placeholder: "Nahrát obrázek",
      },
      VatPayerOrderTaxChange: {
        RadioButtons: {
          [VATPayerOrderTaxChangeType.None]: {
            Label: "",
            Tooltip: "",
          },
          [VATPayerOrderTaxChangeType.IncreaseTax]: {
            Label: "Objednávky o DPH navýšit",
            Tooltip: "Klient zaplatí původní částku + DPH",
          },
          [VATPayerOrderTaxChangeType.DecreaseTax]: {
            Label: "Objednávky o DPH ponížit",
            Tooltip:
              "Klient zaplatí jen původní částku, vy z této částky odvedete DPH",
          },
        },
      },
    },
  },
  Party: {
    Load: "Načíst z ARES",
    Type: "Typ klienta",
    City: "Město",
    FirstName: "Jméno",
    LastName: "Příjmení",
    CompanyName: "Název",
    CompanyNumber: "IČO",
    CompanyRepresentativeFirstName: "Jméno zástupce",
    CompanyRepresentativeLastName: "Příjmení zástupce",
    CompanyRepresentativeBirthDate: "Datum narození zástupce",
    PersonalNumber: {
      Label: "Rodné číslo",
      Tooltip:
        "Zadejte rodné číslo, aby bylo možné propárovat s klientem v myDOCKu. U cizince za / uveďte 0000.",
    },
    IsForeigner: {
      Label: "Cizinec",
    },
    BirthDate: "Datum narození",
    IsVatPayer: "Plátce DPH",
    TaxNumber: "DIČ",
    Street: "Ulice",
    StreetNumber: "Č. popisné",
    OrientationNumber: "Č. orientační",
    PostalCode: "PSČ",
    Identification: {
      Label: "Typ dokladu",
      Description:
        "Po schválení objednávky klientem bude klientovi odeslán email k potvrzení zlatého souhlasu.",
    },
    IdentificationNumber: "Číslo dokladu",
    ContactInformation: {
      Title: "Kontaktní údaje",
      Description:
        "Nastavte kontaktní údaje, které budou na objednávkách a fakturách.",
      PhoneNumber: "Telefon",
      Email: "E-mail",
    },
    BankAccount: {
      Title: "Bankovní spojení",
      Description:
        "Nastavte bankovní spojení, které bude na objednávkách a fakturách.",
      Tooltip:
        "Zadejte číslo účtu, na který budou přicházet platby od klientů. Číslo účtu musí být ve formátu XXXXXX-XXXXXXXXXX/XXXX.",
      BankAccountNumber: "Číslo účtu",
      BankAccountNumberPlaceholder: "XXXXXX-XXXXXXXXXX",
      BankCode: "Kód banky",
      BankCodePlaceholder: "XXXX",
    },
    AresSearch: {
      NotFound: "V ARES nebyla nalezena shoda.",
    },
  },
  Table: {
    NoData: "Žádná data",
    NextPage: "Další strana",
    PrevPage: "Předchozí strana",
    RowsPerPage: "Řádků na stránku",
  },
  BlDatePicker: {
    Locales: {
      One: "den",
      Two: "dny",
      Few: "dny",
      Other: "dnů",
      Many: "dnů",
      Zero: "dnů",
    },
    Placeholder: "Vyberte datum",
    Presets: {
      Today: "Dnes",
      Yesterday: "Včera",
      Last7Days: "Posledních 7 dní",
      Last30Days: "Posledních 30 dní",
      ThisMonth: "Tento měsíc",
      LastMonth: "Minulý měsíc",
    },
  },
  ClientZone: {
    Order: {
      Title: "Objednávka služeb",
      EucsSection: {
        DocumentsNotAvailable:
          "Dokumenty zatím nejsou dostupné, zkuste to prosím později.",
        Loading: "Načítání informací o smlouvě...",
        Title: "Garance EUCS",
        State: "Stav smlouvy:",
        ProductName: "Garance EUCS pro celou rodinu.",
        ErrorLoading: "Nepovedlo se načíst údaje z EUCS.",
        Description: "Níže máte k dispozici dokumentaci k produktu ",
      },
      Approval: {
        SignatureValidation: {
          General: "Nastala chyba",
          InvalidSignatureCode: "Neplatný kód",
          AlreadyCompleted: "Objednávka již byla potvrzena",
          AlreadyRejected: "Objednávka již byla odmítnuta",
        },
        PrepaidApproval: {
          InProgressValidation: {
            General: "Nastala chyba",
            AlreadyInProgress: "Objednávka již byla potvrzena",
            AlreadyRejected: "Objednávka již byla odmítnuta",
          },
          Title: "Potvrzení objednávky",
          Description:
            "Potvrzením objednávky Vám budou zobrazeny platební údaje, jejichž zaplacením závazně potvrdíte objednávku a předáte ji tím k realizaci.",
          Text1:
            "Upozorňujeme Vás, že máte právo odstoupit od této smlouvy bez udání důvodu v rámci 14 dnů od data uzavření smlouvy. Pokud si přejete od smlouvy odstoupit, prosím vyplňte a zašlete nám tento formulář pro odstoupení od smlouvy. Kromě toho máte možnost využít mimosoudní vyřizování stížností spotřebitelů. V případě jakýchkoli problémů či nesrovnalostí se můžete obrátit na příslušný orgán dohledu nebo státního dozoru. Rádi Vám poskytneme veškeré informace týkající se postupů a podmínek mimosoudního vyřizování stížností.",
          Complete: "Chci objednávku potvrdit",
          CompleteText: "Objednávka je již kompletně dokončena.",
          CompletedToast: "Objednávka byla úspěšně potvrzena",
          Reject: "Objednávku odmítám",
          PaymentDescription: "Platební údaje",
          PaymentInfo: "Zaplacením závazně objednáte výše uvedené služby.",
          BankAccount: "Číslo účtu:",
          VariableSymbol: "Variabilní symbol:",
          Price: "Částka:",
          Calendar: {
            Title: "Platební kalendář",
            InvoiceDate: "Období",
            Price: "Částka",
            DueDate: "Splatnost",
            Status: "Stav",
            Action: "Akce",
          },
          Previous: {
            Show: "Zobrazit předchozí",
            Hide: "Skrýt předchozí",
          },
          Next: {
            Show: "Zobrazit další",
            Hide: "Skrýt další",
          },
        },
        Signpost: {
          Title: "Potvrzení objednávky",
          Description:
            "Potvrzením objednávky Vám bude odeslán SMS kód, kterým závazně potvrdíte objednávku a předáte ji tím k realizaci.",
          Text1:
            "Upozorňujeme Vás, že máte právo odstoupit od této smlouvy bez udání důvodu v rámci 14 dnů od data uzavření smlouvy. Pokud si přejete od smlouvy odstoupit, prosím vyplňte a zašlete nám ",
          ThisForm: "tento formulář",
          Text2:
            " pro odstoupení od smlouvy. Kromě toho máte možnost využít mimosoudní vyřizování stížností spotřebitelů. V případě jakýchkoli problémů či nesrovnalostí se můžete obrátit na příslušný orgán dohledu nebo státního dozoru. Rádi Vám poskytneme veškeré informace týkající se postupů a podmínek mimosoudního vyřizování stížností.",
          Accept: "Chci objednávku potvrdit",
          Reject: {
            Btn: "Objednávku odmítám",
            Title: "Potvrzení odmítnutí objednávky",
            Content: "Chcete skutečně odmítnout objednávku?",
            Confirm: "Odmítnout",
          },
        },
        Signature: {
          Title: "Potvrzení objednávky",
          Description:
            "Na Vaše telefonní číslo byl odeslán SMS kód, který opište níže. Následným potvrzením závazně objednáte výše uvedené služby.",
          SignatureCode: "SMS kód",
          ResendSignatureCode: "Znovu poslat kód",
          AreTermsAccepted: {
            Text1: "Souhlasím s ",
            Terms: "obchodními podmínkami",
            Text2: " a s ",
            GDPR: "podmínkami zpracování osobních údajů.",
          },
          AreOrderDetailsAccepted:
            "Potvrzuji správnost objednávky a souhlasím s její realizací.",
          IsRevocationDisabled:
            "Chci poskytnout objednané služby před uplynutím 14 denní lhůty na odstoupení a tímto se práva na tuto lhůtu vzdávám.",
          Complete: "Objednávka zavazující k platbě",
          CompletedToast: "Objednávka byla úspěšně potvrzena",
          Reject: "Objednávku odmítám",
          ResendSignatureCodeTooltip: {
            Enabled: "Kliknutím znovu odešlete SMS kód.",
            Disabled:
              "SMS kód jsme již odeslali, vyčkejte. SMS kód lze odeslat jednou za minutu.",
          },
        },
      },
      Rejected: {
        Title: "Objednávka byla zamítnuta.",
      },
      Revocation: {
        Info: {
          Title: "Odstoupení od smlouvy",
          Text: "Upozorňujeme Vás, že máte právo odstoupit od této smlouvy bez udání důvodu v rámci 14 dnů od data uzavření smlouvy. Pokud si přejete od smlouvy odstoupit, prosím vyplňte a zašlete nám formulář pro odstoupení od smlouvy, který je dostupný pod odkazem níže. Kromě toho máte možnost využít mimosoudní vyřizování stížností spotřebitelů. V případě jakýchkoli problémů či nesrovnalostí se můžete obrátit na příslušný orgán dohledu nebo státního dozoru. Rádi Vám poskytneme veškeré informace týkající se postupů a podmínek mimosoudního vyřizování stížností.",
          Link: "Odstoupit od smlouvy",
        },
        Page: {
          Title: "Odstoupení od smlouvy",
          Description:
            "Máte právo odstoupit od smlouvy bez udání důvodu ve lhůtě 14 dnů ode dne následujícího po dni potvrzení objednávky, kterým je uzavřena smlouva.",
          Example: "Vzor “Odstoupení od smlouvy”",
          ExampleFile: "Poučení o právu odstoupit od smlouvy",
        },
      },
      TermsAndConditions: {
        Title: "Všeobecné obchodní podmínky",
        Subtitle:
          "Všeobecné podmínky poskytování služeb placeného poradenství (dále jen „VOP“)",
      },
      GDPR: {
        Title: "Informace o zpracování osobních údajů",
        Description:
          "Informace dle Nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů (obecné nařízení o ochraně osobních údajů) - GDPR:",
      },
      Error: {
        Title: "Omlouváme se, ale tato objednávka není momentálně k dispozici.",
      },
    },
  },
  StepType: {
    [StepType.Draft]: "Návrh",
    [StepType.ClientAssignment]: "Přiřazeno klientovi",
    [StepType.ClientReview]: "Viděno klientem",
    [StepType.ClientApproval]: "Schváleno klientem",
    ClientApprovalRejected: "Zamítnuto klientem",
    [StepType.ProcessingServices]: "Zpracování služeb",
    [StepType.ClientInvoiceIssuance]: "Vystavení faktury",
    ClientInvoiceIssuanceRejected: "Faktura stornována",
    InvoiceAfterDueDate: "Faktura po splatnosti",
    [StepType.ClientInvoicePayment]: "Zaplacení faktury",
    [StepType.EnterpriseInvoiceIssuanceAndPayment]: "Vyplacení provize",
    [StepType.ClientInvoiceRecurringPayment]: "Průběžná fakturace",
    [StepType.Completed]: "Dokončeno",
    [StepType.ClientPrepaidPaymentApproval]: "Zaplaceno/Potvrzeno klientem",
    ClientPrepaidPaymentRejected: "Platba klientem zamítnuta",
    ClientPrepaidPaymentApprovalRecurring: "Potvrzeno klientem",
    ClientPrepaidPaymentApprovalSingle: "Zaplaceno klientem",
  },
  PaymentCalendarItemStatus: {
    [PaymentCalendarItemStatus.Created]: "Čeká na fakturaci",
    PrepaidPaymentCreated: "Čeká na zaplacení",
    ZeroPrice: "Automaticky označeno za zaplacené",
    [PaymentCalendarItemStatus.Canceled]: "Fakturace zrušena",
    [PaymentCalendarItemStatus.SentToClient]: "Odesláno klientovi",
    [PaymentCalendarItemStatus.PaidByClient]: "Zaplaceno klientem",
    [PaymentCalendarItemStatus.CommissionPaid]: "Provize vyplacena",
  },
  PaymentCalendarItemStatusForClient: {
    [PaymentCalendarItemStatus.Created]: "Čeká na zaplacení",
    [PaymentCalendarItemStatus.Canceled]: "Fakturace zrušena",
    [PaymentCalendarItemStatus.SentToClient]: "Čeká na zaplacení",
    [PaymentCalendarItemStatus.PaidByClient]: "Zaplaceno",
    [PaymentCalendarItemStatus.CommissionPaid]: "Zaplaceno",
  },
  OrderFrequency: {
    [OrderFrequency.Weekly]: "Týden",
    [OrderFrequency.Monthly]: "Měsíc",
    [OrderFrequency.Quarterly]: "Čtvrtletí",
    [OrderFrequency.Yearly]: "Rok",
  },
  IdentificationType: {
    [IdentificationType.IDCard]: "Občanský průkaz",
    [IdentificationType.Other]: "Jiný",
  },
  OrderPeriodicity: {
    [OrderPeriodicity.Single]: "Jednorázová",
    [OrderPeriodicity.Periodic]: "Pravidelná",
  },
  OrderPaymentType: {
    [OrderPaymentType.PaymentAfterProcessingOrder]: "Po zpracování služeb",
    [OrderPaymentType.Prepaid]: "Předplacená",
  },
  OrderServiceRate: {
    [OrderServiceRate.HourRate]: "Hodinová",
    [OrderServiceRate.FixedPrice]: "Fixní",
  },
  UpcomingOrdersDateRange: {
    [UpcomingOrdersDateRange.Week]: "Nejbližší týden",
    [UpcomingOrdersDateRange.Month]: "Nejbližší měsíc",
    [UpcomingOrdersDateRange.Quarter]: "Nejbližší čtvrtletí",
    [UpcomingOrdersDateRange.Year]: "Nejbližší rok",
  },

  EucsDisableService: {
    Title: "Upozornění",
    Description:
      "Možnost sjednat Garanci EUCS Vám bude umožněna až po podepsání smlouvy mezi Vašim HSP a EUCS. K podpisu smlouvy s EUCS je nutné kontaktovat jejich obchodní oddělení.",
  },
  MissingUserCompanyWarning: {
    Title: "Upozornění",
    Description:
      "Objednávku není možné vytvořit, protože nemáte vyplněné fakturační údaje pro fakturaci provize z této objednávka. Vyplňte prosím tyto údaje před vystavením objednávky.",
    Button: "Vyplnit údaje",
  },
  Autocomplete: {
    ClearText: "Smazat",
    CloseText: "Zavřít",
    OpenText: "Otevřít",
    NoOptionsText: "Žádné možnosti",
    LoadingText: "Načítání...",
  },
  Commission: {
    Create: {
      OpenDialogButton: "Nové nastavení",
      Title: "Nastavení provizí",
      Tooltip:
        "Při uzávěrce se počítá provize dle nastavení z období, kdy byla objednávka schválena klientem.",
      Description:
        "Nastavte, jak budou rozděleny provize za poskytnuté služby.",
      DateFrom: "Platnost od",
      EnterpriseCommissionRate: "Podíl pro HSP",
      AdvisorCommissionRate: "Podíl pro poradce",
      Validation: {
        General: "Nastala chyba",
        DateFromIsInConflictWithAnotherCommission:
          "Datum od je v konfliktu s jiným nastavením provizí",
      },
    },
  },
  FioSettings: {
    Automation: {
      Title: "Automatizace banky",
      Tooltip:
        "U vybraných bank umožňujeme propojení bankovního účtu se systémem a díky tomu kontrolovat přijaté platby na základě variabilního symbolu. Aktuálně podporujeme FIO banku.",
      Description:
        "Nastavte informace pro automatickou kontrolu přijatých plateb a generování platebních příkazů.",
      Tutorial: {
        Part1:
          "Informace, jak a kde nastavíte API klíč pro přístup do banky naleznete v ",
        Part2: "TOMTO",
        Part3: " dokumentu.",
      },
    },
    IsEnabled: {
      Label: "Jedná se o FIO účet a chci zapnout automatické párování plateb",
    },
    IsMatchingPaymentsEnabled: {
      Label: "Zapnout párování přijatých plateb",
    },
    IsSendingPaymentsEnabled: {
      Label: "Zapnout generování platebních příkazů",
    },
    FioApiKey: {
      Label: "FIO API klíč",
    },
  },
  SignedOut: {
    Title: "Odhlášení",
    Description: "Pro práci s aplikací je nutno se přihlásit.",
    Description2:
      "Přihlaste se do svého myDOCKu, pak otevřete placené poradenství.",
  },
  OrderPaymentFrequency: {
    [OrderPaymentFrequency.Single]: "Jednorázová",
    [OrderPaymentFrequency.Monthly]: "Měsíční",
    [OrderPaymentFrequency.Quarterly]: "Čtvrtletní",
    [OrderPaymentFrequency.Semiannually]: "Půlroční",
    [OrderPaymentFrequency.Yearly]: "Roční",
  },
  ServicePackageType: {
    [ServicePackageType.ProvidedService]: "Jednotlivé služby",
    [ServicePackageType.Package]: "Balíček",
  },
};
