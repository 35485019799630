import { ObjPathProxy, createProxy, getPath } from "ts-object-path";
import i18next, { ResourceLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { logError } from "ErrorService";
import {
  CompleteClientApprovalStepError,
  IdentificationType,
  InProgressClientApprovalStepError,
  OrderFrequency,
  OrderPaymentFrequency,
  OrderPaymentType,
  OrderPeriodicity,
  OrderServiceProductInstitutionStatus,
  OrderServiceRate,
  PaymentCalendarItemStatus,
  StepType,
  VATPayerOrderTaxChangeType,
} from "Api/Api";
import { UpcomingOrdersDateRange } from "Components/Dashboard/UpcomingPeriodicOrders";
import { ServicePackageType } from "Components/Orders/Draft/StepFormValidationSchema";

export interface ResourceDictionary extends ResourceLanguage {
  Common: {
    AppName: string;
    Years: string;
    Years_one: string;
    Years_two: string;
    Years_few: string;
    Years_other: string;
    YearsLabel: string;
    YearsLabel_one: string;
    YearsLabel_few: string;
    YearsLabel_other: string;
    Month: string;
    Months: string;
    Months_one: string;
    Months_two: string;
    Months_few: string;
    Months_other: string;
    Continue: string;
    Step: string;
    TextCopied: string;
    Yes: string;
    No: string;
    Choose: string;
    Download: string;
    Downloading: string;
    UnsupportedFileType: string;
    Email: string;
    Password: string;
    ExampleLogin: string;
    PiecesShort: string;
    PiecesSuffix: string;
    Active: string;
    Inactive: string;
    Actual: string;
    Blocked: string;
    Canceled: string;
    Cancel: string;
    Close: string;
    Confirm: string;
    Ended: string;
    All: string;
    AllStatuses: string;
    PrivacyPolicyLink: string;
    Error: string;
    Save_FirstUppercase: string;
    Save: string;
    Reset: string;
    WithTax: string;
    WithoutTax: string;
    Edit: string;
    Back: string;
    Delete: string;
    SaveAndClose: string;
    PreviousStep: string;
    SaveSuccess: string;
    CompanyNumber: string;
    TaxNumber: string;
    PersonalNumber: string;
    ProcessedByRepresentative: string;
    SendByRepresentative: string;
    InvoceAfterDueDate: string;
    NoCommission: string;
  };
  Errors: {
    ApplicationError: {
      Title: string;
      Home: string;
    };
    Error404: {
      Subtitle: string;
      Home: string;
    };
    Error403: {
      Subtitle: string;
    };
    Error500: string;
  };
  SignIn: {
    Error: {
      General: string;
      InvalidLogin: string;
      InvalidCredentials: string;
      ApplicationVerification: string;
      PhoneNumberNotFound: string;
      SmsSendingFailed: string;
      InvalidToken: string;
      AccountNotFound: string;
    };
  };
  SignUp: {
    Error: {
      General: string;
      UserExists: string;
      WeakPassword: string;
    };
  };
  SetPassword: {
    Error: {
      General: string;
      TokenNotFound: string;
      WeakPassword: string;
    };
  };
  Validation: {
    Required: string;
    InvalidEmail: string;
    AlphabetsOnly: string;
    DigitsOnly: string;
    InvalidPhone: string;
    InvalidFormat: string;
    InvalidBankAccount: string;
    InvalidPersonalIdentificationNumber: string;
    InvalidTaxNumber: string;
    InvalidCompanyNumber: string;
    IsTrue: string;
    Number: {
      Integer: string;
      Min: string;
      MoreThen: string;
    };
    String: {
      Min: string;
      Max: string;
      StrictLength: string;
      InvalidFormat: string;
    };
    Array: {
      Min: string;
      Min_one: string;
      Min_few: string;
      Min_other: string;
    };
    FileTooBig: string;
  };
  Transactions: {
    Type: {
      Buy: string;
      Sell: string;
      Withdrawal: string;
      LeadReward: string;
    };
    Status: {
      Completed: string;
      PendingTrade: string;
      WithdrawRequested: string;
      Withdrawn: string;
    };
    PartyType: {
      NaturalPerson: string;
      LegalEntity: string;
      SelfEmployed: string;
    };
    EnterpriseMode: {
      Strict: string;
      Free: string;
    };
    EnterpriseEmailCommunicationMode: {
      OnlySupplierCompany: string;
      SupplierCompanyAndUser: string;
    };
    PrepaidOrderReminderForPaymentCalendarItemsStatus: {
      Active: string;
      Inactive: string;
    };
    PriceTaxTypeName: {
      WithTax: string;
      WithoutTax: string;
    };
    EnterpriseProductInvoiceForType: {
      User: string;
      Enterprise: string;
    };
  };
  Dashboard: {
    PageName: string;
    Title: string;
    Subtitle: string;
    Statistics: {
      Leads: string;
      Clients: string;
      Orders: string;
      ExpectedCommission: string;
      ExpectedCommissionTooltip: string;
      PaidCommission: string;
      PaidCommissionTooltip: string;
    };
    OrdersInProgress: {
      Title: string;
    };
    Anniversary: {
      Title: string;
      Tooltip: string;
    };
  };

  Orders: {
    PageName: string;
    List: {
      Title: string;
      Query: string;
      DateRangeTooltip: string;
    };
    Detail: {
      DetailTitle: string;
      DraftTitle: string;
      CreateTitle: string;
      NotFound: string;
      Provider: {
        Title: string;
        Label: string;
      };
      Icons: {
        ISDOC: string;
        ISDOCNotAvailable: string;
        ISDOCNotAvailableZeroPrice: string;
        Pdf: string;
        PdfNotAvailable: string;
        PdfNotAvailableZeroPrice: string;
      };
      Client: {
        Status: {
          New: string;
          Existing: string;
        };
        Search: string;
        Load: string;
        Title: string;
        Type: string;
        FirstName: string;
        LastName: string;
        CompanyName: string;
        IsVatPayer: string;
        TaxNumber: string;
        CompanyNumber: string;
        Street: string;
        StreetNumber: string;
        City: string;
        PostalCode: string;
        PhoneNumber: string;
        Email: string;
      };
      Periodicity: {
        Title: {
          Single: string;
          Periodic: string;
        };
        Label: string;
        Disclaimer: string;
        Frequency: {
          Label: string;
          Tooltip: string;
        };
      };
      Payment: {
        Title: string;
        Label: string;
        PaymentType: {
          Label: string;
        };
        Periodicity: {
          Label: string;
          Tooltip: string;
        };
        ServicePackage: {
          Type: string;
        };
        RadioButtons: {
          PeriodPostfix: Record<OrderPaymentFrequency, string>;
          Tooltip: Record<OrderPaymentFrequency, string>;
        };
        PaymentFrequencyDate: {
          From: {
            Label: string;
          };
          To: {
            Label: string;
          };
        };
        isIndefiniteEnd: {
          Label: string;
        };
        OverallPrice: string;
        MonthPrice: string;
      };
      ClientConsents: {
        IsClientPersonalDataProcessingConsent: string;
        IsClientElectronicCommunicationConsent: string;
      };
      Services: {
        Title: string;
        ServiceRate: string;
        HourlyCharge: string;
        Offers: string;
        NoServiceVariants: string;
        NoServiceVariantsAndPackages: string;
        Variant: string;
        TotalPrice: {
          Single: {
            FixedRate: string;
            HourRate: string;
          };
          Periodic: {
            FixedRate: string;
            HourRate: string;
          };
        };
      };
      Note: {
        Title: string;
        Label: string;
        Placeholder: string;
      };
      Summary: {
        Title: string;
        AreTermsAccepted: {
          Label: string;
          Validation: string;
        };
        Process: string;
        OrderNumber: string;
        CompanyNumber: string;
        TaxNumber: string;
        ApprovalTerms: string;
        GDPR: string;
        Phone: string;
        Iban: string;
        Email: string;
        VariableSymbol: string;
        Services: {
          Title: string;
          Price: {
            Single: string;
            Periodic: string;
          };
          PricePerFrequency: {
            Periodic: string;
          };
          Periodicity: {
            Title: string;
            Dates: string;
            DateWithoutEnd: string;
          };
          ApprovalDate: string;
          ApprovalPaymentDate: string;
          RejectionDate: string;
          PackageIncludeText: string;
        };
        Note: string;
      };
      SavedSuccess: string;
      DraftForm: {
        Delete: {
          Button: string;
          Title: string;
          Confirmation: string;
        };
      };
    };
    Completed: {
      Title: string;
      Description: string;
      CompleteOrder: string;
      RepeatOrder: string;
      CancelRepetitionDialog: {
        OpenDialogButton: string;
        Title: string;
        Text: string;
      };
      SetRepetitionDialog: {
        OpenDialogButton: string;
        Title: string;
      };
    };
    Client: {
      Title: string;
      Representative: string;
    };
    Supplier: {
      Title: string;
    };
    Workflow: {
      Services: {
        Title: string;
        TotalPrice: {
          Single: string;
          Periodic: string;
        };
        Delete: {
          Title: string;
          Text: string;
          Disabled: {
            TooLate: string;
            LastService: string;
            IsReadOnly: string;
          };
        };
        EucsService: {
          State: string;
          Documents: string;
          Loading: string;
          ErrorLoading: string;
          Status: Record<OrderServiceProductInstitutionStatus, string>;
          Cancel: {
            Title: string;
            Tooltip: string;
            Text: string;
            Disabled: {
              Status: Record<OrderServiceProductInstitutionStatus, string>;
              IsReadOnly: string;
            };
          };
          Create: {
            Text: string;
            Disabled: {
              IsReadOnly: string;
            };
          };
        };
      };
      Workflow: {
        Title: string;
        SeenByClientStep: {
          Text: string;
          Send: string;
        };
        ClientAssignmentStep: {
          Send: string;
          Text: string;
          WasSent: string;
        };
        ProcessingServicesStep: {
          Description: string;
          Complete: string;
          DateProcessed: string;
          Confirm: {
            Title: string;
            Content: string;
          };
          Revocation: {
            True: string;
            False: string;
          };
        };
        RecurringPaymentStep: {
          Description: string;
          Header: {
            InvoiceDate: string;
            Price: string;
            Commission: string;
            Status: string;
            Actions: string;
          };
          Cancel: {
            Title: string;
            Text: string;
          };
          Pay: {
            Title: string;
            Text: string;
          };
          SendAndPay: {
            Title: string;
            Text: string;
          };
          Remind: {
            Title: string;
            Text: string;
            Success: string;
          };
          CancelInvoice: {
            Title: string;
            Text: string;
            Status: string;
          };
          WaitingForCommission: string;
          ZeroPrice: string;
          ReminderSentStatus: string;
          Previous: {
            Show: string;
            Hide: string;
          };
          Next: {
            Show: string;
            Hide: string;
          };
        };
        ClientInvoiceIssuanceStep: {
          Description: string;
          Complete: string;
          ZeroPrice: string;
        };
        ClientInvoicePaymentStep: {
          ZeroPrice: string;
          Automation: {
            IsEnabled: string;
            IsDisabled: {
              Part1: string;
              Part2: string;
              Link: string;
              ContactAdmin: string;
            };
          };
          Reminder: {
            LastSent: string;
            Send: string;
            WasSent: string;
          };
          Pay: string;
          CancelInvoice: string;
        };
        ClientPrepaidPaymentApprovalStep: {
          Automation: {
            IsEnabled: string;
            IsDisabled: {
              Part1: string;
              Part2: string;
              Link: string;
              ContactAdmin: string;
            };
          };
          Pay: string;
          ClientApproveInfo: string;
        };
        EnterpriseInvoiceIssuanceAndPaymentStep: {
          Description: string;
          Completed: string;
          CompletedZeroPrice: string;
        };
      };
    };
    DeleteConfirmDialog: {
      Title: string;
      Text: string;
      Tooltip: {
        Enabled: string;
        Disabled: string;
        IsReadOnly: string;
      };
    };
    EarlyDoneOrderRecurringPaymentDialog: {
      Title: string;
      Text: string;
      Tooltip: {
        Enabled: string;
        Disabled: string;
        IsReadOnly: string;
      };
    };
    CancelInvoiceConfirmDialog: {
      Title: string;
      Text: string;
      Tooltip: {
        Enabled: string;
        Disabled: string;
        IsReadOnly: string;
        IsZeroPrice: string;
      };
    };
    CancelInvoiceForClientByOrderWaning: {
      Title: string;
      Text: string;
    };
  };
  StepType: Record<StepType, string> & {
    ClientApprovalRejected: string;
    ClientInvoiceIssuanceRejected: string;
    InvoiceAfterDueDate: string;
    ClientPrepaidPaymentRejected: string;
    ClientPrepaidPaymentApprovalRecurring: string;
    ClientPrepaidPaymentApprovalSingle: string;
  };
  IdentificationType: Record<IdentificationType, string>;
  OrderFrequency: Record<OrderFrequency, string>;
  PaymentCalendarItemStatus: Record<PaymentCalendarItemStatus, string> & {
    PrepaidPaymentCreated: string;
    ZeroPrice: string;
  };
  PaymentCalendarItemStatusForClient: Record<PaymentCalendarItemStatus, string>;
  OrderPeriodicity: Record<OrderPeriodicity, string>;
  OrderPaymentType: Record<OrderPaymentType, string>;
  OrderPaymentFrequency: Record<OrderPaymentFrequency, string>;
  OrderServiceRate: Record<OrderServiceRate, string>;
  UpcomingOrdersDateRange: Record<UpcomingOrdersDateRange, string>;
  Settings: {
    PageName: string;
    Enterprise: {
      Title: string;
      Url: string;
      Save: {
        Success: string;
      };
      Mode: {
        Title: string;
        Description: string;
        Tooltip: {
          Text: string;
          Strict: string;
          Free: string;
        };
        Confirm: {
          Title: string;
          Text: string;
        };
      };
      Color: {
        Title: string;
        Description: string;
        Tooltip: string;
      };
      LogoBase64: {
        Title: string;
        Description: string;
        Tooltip: string;
        Label: string;
        Placeholder: string;
      };
      EmailMode: {
        Title: string;
        Description: string;
        OnlySupplier: string;
        SupplierAndUser: string;
        Tooltip: {
          Title: string;
          OnlySupplier: string;
          SupplierAndUser: string;
          Example: string;
          ExampleNameUser: string;
          ExampleNameSupplier: string;
        };
      };
      EucsMode: {
        Title: string;
        Description: string;
        CompanyNumber: string;
        InvoiceForType: {
          HSP: string;
          User: string;
        };
      };
      EucsStats: {
        Title: string;
        Description: string;
      };
      PrepaidOrderReminderForPaymentCalendarItemsStatus: {
        Title: string;
        Description: string;
        Tooltip: {
          Text: string;
        };
      };
    };
    Services: {
      Title: string;
      Url: string;
      ProvidedServices: {
        Title: string;
        Description: string;
        Tooltip: string;
      };
      HourRateService: {
        Title: string;
        Description: string;
        Tooltip: {
          Description: string;
          StrictModeHeader: string;
          StrictModeDescription: string;
          FreeModeHeader: string;
          FreeModeDescription: string;
        };
      };
      ServiceSettings: {
        Title: string;
        Description: string;
        Tooltip: string;
        CanUserSetPrice: string;
        MaximalPriceRangeRate: string;
      };
      VariantIsRequired: string;
    };
    Commission: {
      Title: string;
      Description: string;
      Tooltip: string;
      Url: string;
      EnterpriseCommissionRate: string;
      AdvisorCommissionRate: string;
      TotalCommission: string;
      EnterpriseModeFree: string;
    };
    Companies: {
      Title: string;
      Url: string;
    };
    Invoices: {
      Title: string;
      Url: string;
      Invoice: string;
      Company: {
        Title: string;
        Description: string;
        Tooltip: string;
        Add: string;
      };
      InvoicesList: {
        Title: string;
        Description: string;
        Tooltip: string;
        Pdf: string;
        ISDOC: string;
        Number: string;
        CustomerFormattedName: string;
        InvoiceDate: string;
        PriceWithoutTax: string;
        PriceWithTax: string;
        DownloadAll: {
          Button: string;
          File: string;
        };
      };
    };
    ServicePackages: {
      Title: string;
      Description: string;
      Tooltip: string;
      Url: string;
      CreateButton: string;
      List: {
        Services: string;
        Variants: string;
        Delete: {
          ModalTitle: string;
          ModalContent: string;
          SuccessToast: string;
        };
      };
      PackageServiceSettings: {
        Title: string;
        Description: string;
        Tooltip: string;
        Single: {
          MinimalPriceLabel: string;
          MaximalPriceLabel: string;
        };
        Monthly: {
          MinimalPriceLabel: string;
          MaximalPriceLabel: string;
        };
        IsCreatePackageByAdvisorLabel: string;
        NoLimitsLabel: string;
        Validation: {
          MinMustBeLessThanMax: string;
          MaxMustBeGreaterThanMin: string;
        };
      };
      Detail: {
        Title: {
          New: string;
          Existing: string;
        };
        Form: {
          Title: string;
          FormDescription: string;
          Name: {
            Label: string;
            Placeholder: string;
          };
          Description: {
            Label: string;
            Placeholder: string;
          };
          Services: {
            Title: string;
            Description: string;
            Validation: string;
          };
          PriceTaxType: {
            Label: string;
            Tooltip: string;
          };
          Variants: {
            Title: string;
            Description: string;
            Validation: {
              AtLeastOneSelected: string;
              Range: {
                Min: string;
                Max: string;
              };
            };
            PriceWithoutTax: {
              Label: string;
              Placeholder: string;
            };
          };
          Save: string;
          Delete: {
            Button: string;
            Title: string;
            Confirmation: string;
          };
        };
      };
    };
    Cutoff: {
      Title: string;
      Url: string;
      New: {
        Title: string;
        Tooltip: string;
        Run: string;
        EmptyOrders: string;
        Success: string;
        NotFound: string;
        Eucs: {
          Price: {
            Label: string;
            Tooltip: string;
          };
        };
      };
      Create: {
        Validation: {
          General: string;
          ForThisSupplierCutoffAlreadyRunning: string;
          DateFromIsInConflictWithPreviousCutoffDateTo: string;
        };
      };
      Dialog: {
        Title: string;
        Label: string;
        Tooltip: string;
        DateFrom: string;
        DateTo: string;
        IsConfirmed: string;
        Draft: string;
        Warning: {
          T1: string;
          T2: string;
          T3: string;
        };
      };
      List: {
        Title: string;
        Description: string;
        Tooltip: string;
        DateFrom: string;
        DateProcessed: string;
        DownloadZipInvoiceForCompanyByUser: string;
        DownloadZipInvoiceForClientByOrder: string;
        DownloadPdf: string;
        GeneratePayments: string;
        GeneratePaymentsSuccess: string;
        CompanyName: string;
      };
    };
  };
  Companies: {
    List: {
      Title: string;
      Description: string;
      Tooltip: string;
      Add: string;
      CompanyNumber: string;
      TaxNumber: string;
      IsDefault: string;
    };
    Detail: {
      Title: {
        New: string;
        Edit: string;
      };
      BasicInformation: {
        Title: string;
        Description: string;
        Tooltip: string;
      };
      OtherInformation: {
        Title: string;
        Tooltip: string;
        IsDefault: string;
        CanBeSelectedToOrder: string;
        CanBeSelectedToOrderTooltip: string;
        InvoiceNumberSeries: string;
        InvoiceNumberSeriesPlaceholder: string;
        InvoiceNumberSeriesDescription: string;
        InvoiceNumberSeriesWarning: string;
      };
      Logo: {
        Title: string;
        Tooltip: string;
        Description: string;
        Label: string;
        Placeholder: string;
      };
      VatPayerOrderTaxChange: {
        RadioButtons: Record<
          VATPayerOrderTaxChangeType,
          {
            Label: string;
            Tooltip: string;
          }
        >;
      };
    };
  };
  Party: {
    Load: string;
    Type: string;
    FirstName: string;
    LastName: string;
    CompanyName: string;
    IsVatPayer: string;
    TaxNumber: string;
    CompanyNumber: string;
    CompanyRepresentativeFirstName: string;
    CompanyRepresentativeLastName: string;
    CompanyRepresentativeBirthDate: string;
    PersonalNumber: {
      Label: string;
      Tooltip: string;
    };
    IsForeigner: {
      Label: string;
    };
    BirthDate: string;
    Street: string;
    StreetNumber: string;
    OrientationNumber: string;
    City: string;
    PostalCode: string;
    Identification: {
      Description: string;
      Label: string;
    };
    IdentificationNumber: string;
    ContactInformation: {
      Title: string;
      Description: string;
      PhoneNumber: string;
      Email: string;
    };
    BankAccount: {
      Title: string;
      Description: string;
      Tooltip: string;
      BankAccountNumber: string;
      BankAccountNumberPlaceholder: string;
      BankCode: string;
      BankCodePlaceholder: string;
    };
    AresSearch: {
      NotFound: string;
    };
  };
  Table: {
    NoData: string;
    RowsPerPage: string;
    NextPage: string;
    PrevPage: string;
  };
  BlDatePicker: {
    Placeholder: string;
    Presets: {
      Today: string;
      Yesterday: string;
      Last7Days: string;
      Last30Days: string;
      ThisMonth: string;
      LastMonth: string;
    };
    Locales: {
      One: string;
      Two: string;
      Few: string;
      Other: string;
      Many: string;
      Zero: string;
    };
  };
  ClientZone: {
    Order: {
      Title: string;
      EucsSection: {
        DocumentsNotAvailable: string;
        Loading: string;
        Title: string;
        Description: string;
        ProductName: string;
        ErrorLoading: string;
        State: string;
      };
      Approval: {
        SignatureValidation: Record<CompleteClientApprovalStepError, string> & {
          General: string;
        };
        PrepaidApproval: {
          Title: string;
          Description: string;
          Text1: string;
          Complete: string;
          CompleteText: string;
          CompletedToast: string;
          Reject: string;
          PaymentDescription: string;
          PaymentInfo: string;
          BankAccount: string;
          VariableSymbol: string;
          Price: string;
          InProgressValidation: Record<
            InProgressClientApprovalStepError,
            string
          > & {
            General: string;
          };
          Calendar: {
            Title: string;
            InvoiceDate: string;
            Price: string;
            DueDate: string;
            Status: string;
            Action: string;
          };
          Previous: {
            Show: string;
            Hide: string;
          };
          Next: {
            Show: string;
            Hide: string;
          };
        };
        Signpost: {
          Title: string;
          Description: string;
          Text1: string;
          ThisForm: string;
          Text2: string;
          Accept: string;
          Reject: {
            Btn: string;
            Title: string;
            Content: string;
            Confirm: string;
          };
        };
        Signature: {
          Title: string;
          Description: string;
          SignatureCode: string;
          ResendSignatureCode: string;
          AreTermsAccepted: {
            Text1: string;
            Terms: string;
            Text2: string;
            GDPR: string;
          };
          AreOrderDetailsAccepted: string;
          IsRevocationDisabled: string;
          Complete: string;
          CompletedToast: string;
          Reject: string;
          ResendSignatureCodeTooltip: {
            Enabled: string;
            Disabled: string;
          };
        };
      };
      Rejected: {
        Title: string;
      };
      Revocation: {
        Info: {
          Title: string;
          Text: string;
          Link: string;
        };
        Page: {
          Title: string;
          Description: string;
          Example: string;
          ExampleFile: string;
        };
      };
      TermsAndConditions: {
        Title: string;
        Subtitle: string;
      };
      GDPR: {
        Title: string;
        Description: string;
      };
      Error: {
        Title: string;
      };
    };
  };
  EucsDisableService: {
    Title: string;
    Description: string;
  };
  MissingUserCompanyWarning: {
    Title: string;
    Description: string;
    Button: string;
  };
  Autocomplete: {
    ClearText: string;
    CloseText: string;
    OpenText: string;
    NoOptionsText: string;
    LoadingText: string;
  };
  Commission: {
    Create: {
      OpenDialogButton: string;
      Title: string;
      Tooltip: string;
      Description: string;
      DateFrom: string;
      EnterpriseCommissionRate: string;
      AdvisorCommissionRate: string;
      Validation: {
        General: string;
        DateFromIsInConflictWithAnotherCommission: string;
      };
    };
  };
  FioSettings: {
    Automation: {
      Title: string;
      Tooltip: string;
      Description: string;
      Tutorial: {
        Part1: string;
        Part2: string;
        Part3: string;
      };
    };
    IsEnabled: {
      Label: string;
    };
    IsMatchingPaymentsEnabled: {
      Label: string;
    };
    IsSendingPaymentsEnabled: {
      Label: string;
    };
    FioApiKey: {
      Label: string;
    };
  };
  SignedOut: {
    Title: string;
    Description: string;
    Description2: string;
  };
  ServicePackageType: Record<ServicePackageType, string>;
}

export const Resources = createProxy<ResourceDictionary>();

export function getResourcePath(
  proxy: ObjPathProxy<ResourceDictionary, string>,
): string {
  const path = getPath(proxy);

  if (!path || path.length === 0) return "";
  if (path.length === 1) return path[0].toString();
  return `${path[0].toString()}:${path.slice(1).join(".")}`;
}

export function useResource() {
  const { t: i18Translation } = useTranslation();

  return {
    t: (
      resourcePath: ObjPathProxy<ResourceDictionary, string>,
      options?: any,
    ) =>
      i18Translation(
        getResourcePath(resourcePath),
        options,
      ) as unknown as string,
  };
}

export function useServerError(
  parentObject: ObjPathProxy<ResourceDictionary, object>,
  fallbackProperty: ObjPathProxy<ResourceDictionary, string>,
) {
  const { t } = useResource();
  return {
    translateError: (code: string | null | undefined) => {
      if (!code) {
        return null;
      }

      const newCode = `${getResourcePath(parentObject as any)}.${code}`;

      const isCodeTranslated = i18next.exists(newCode);

      if (!isCodeTranslated) {
        console.log(`Not found serverError translation for ${newCode}`);
        logError(
          new Error(`Not found serverError translation for ${newCode}`),
          null,
          false,
        );
      }

      const resource = isCodeTranslated
        ? i18next.t(newCode)
        : t(fallbackProperty);

      return resource as string;
    },
  };
}

export function getTranslation(
  resourcePath: ObjPathProxy<ResourceDictionary, string>,
) {
  return i18next.t(getResourcePath(resourcePath));
}
