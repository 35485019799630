import { combineReducers } from "@reduxjs/toolkit";
import {
  enterpriseBasicSettingsSaga,
  enterpriseBasicSettingsSlice,
} from "State/Enterprises/BasicSettings/EnterpriseBasicSettingsSlice";
import {
  enterpriseLogoSaga,
  enterpriseLogoSlice,
} from "State/Enterprises/BasicSettings/EnterpriseLogoSlice";
import {
  postEnterpriseChangeModeSlice,
  postEnterpriseChangeModeSaga,
} from "State/Enterprises/BasicSettings/PostEnterpriseChangeModeSlice";
import {
  putEnterpriseDesignSettingsSlice,
  putEnterpriseDesignSettingsSaga,
} from "State/Enterprises/BasicSettings/PutEnterpriseDesignSettings";
import {
  putEnterpriseCommunicationModeSlice,
  putEnterpriseCommunicationModeSaga,
} from "State/Enterprises/BasicSettings/PutEnterpriseCommunicationModeSlice";
import {
  getEnterpriseCommissionSettingsSaga,
  getEnterpriseCommissionSettingsSlice,
} from "State/Enterprises/Commission/CommissionSettingsSlice";
import {
  putEnterprisesCommissionSettingsSaga,
  createEnterprisesCommissionSettingsSlice,
} from "State/Enterprises/Commission/CreateCommissionSettingsSlice";
import {
  putEnterpriseServiceSettingsSlice,
  putEnterpriseServiceSettingsSaga,
} from "State/Enterprises/Services/PutServiceSettingsSlice";
import {
  putEnterpriseServicesSlice,
  putEnterpriseServicesSaga,
} from "State/Enterprises/Services/PutServicesSlice";
import {
  getEnterpriseServiceSettingsSlice,
  getEnterpriseServiceSettingsSaga,
} from "State/Enterprises/Services/ServiceSettingsSlice";
import {
  getEnterpriseServicesSaga,
  getEnterpriseServicesSlice,
} from "State/Enterprises/Services/ServicesSlice";
import { nameof } from "Utils/ObjectUtils";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { all } from "typed-redux-saga";
import {
  getHasAccessToProductSlice,
  getHasAccessToProductSaga,
} from "State/Enterprises/Products/HasAccessToProductSlice";
import {
  putEnterpriseProductAdditionalInfoSaga,
  putEnterpriseProductAdditionalInfoSlice,
} from "State/Enterprises/Products/PutEnterpriseProductAdditionalInfo";
import {
  getEnterpriseProductAdditionalInfoSaga,
  getEnterpriseProductAdditionalInfoSlice,
} from "State/Enterprises/Products/GetEnterpriseProductAdditionalInfo";
import {
  getEnterpriseProductCountByUsersSaga,
  getEnterpriseProductCountByUsersSlice,
} from "State/Enterprises/Products/GetEnterpriseProductCountByUsers";

export const enterprisesReducer = combineReducers({
  basicSettings: enterpriseBasicSettingsSlice.reducer,
  logo: enterpriseLogoSlice.reducer,
  postEnterpriseChangeMode: postEnterpriseChangeModeSlice.reducer,
  putEnterpriseDesignSettings: putEnterpriseDesignSettingsSlice.reducer,
  commissionSettings: getEnterpriseCommissionSettingsSlice.reducer,
  createCommissionSettings: createEnterprisesCommissionSettingsSlice.reducer,
  services: getEnterpriseServicesSlice.reducer,
  putServices: putEnterpriseServicesSlice.reducer,
  serviceSettings: getEnterpriseServiceSettingsSlice.reducer,
  puServiceSettings: putEnterpriseServiceSettingsSlice.reducer,
  putEnterpriseCommunicationMode: putEnterpriseCommunicationModeSlice.reducer,
  getHasAccessToProduct: getHasAccessToProductSlice.reducer,
  putEnterpriseProductAdditionalInfo:
    putEnterpriseProductAdditionalInfoSlice.reducer,
  getEnterpriseProductAdditionalInfo:
    getEnterpriseProductAdditionalInfoSlice.reducer,
  getEnterpriseProductCountByUsers:
    getEnterpriseProductCountByUsersSlice.reducer,
});

type EnterpriseState = ReturnType<typeof enterprisesReducer>;

export function persistedEnterpriseReducer() {
  return persistReducer(
    {
      key: "enterprise",
      storage,
      whitelist: [
        nameof<EnterpriseState>("basicSettings") as string,
        nameof<EnterpriseState>("logo") as string,
      ],
    },
    enterprisesReducer,
  );
}

export function* watchEnterprisesSagas() {
  yield all([
    enterpriseBasicSettingsSaga(),
    postEnterpriseChangeModeSaga(),
    getEnterpriseCommissionSettingsSaga(),
    putEnterprisesCommissionSettingsSaga(),
    getEnterpriseServicesSaga(),
    putEnterpriseServicesSaga(),
    getEnterpriseServiceSettingsSaga(),
    putEnterpriseServiceSettingsSaga(),
    putEnterpriseDesignSettingsSaga(),
    enterpriseLogoSaga(),
    putEnterpriseCommunicationModeSaga(),
    getHasAccessToProductSaga(),
    putEnterpriseProductAdditionalInfoSaga(),
    getEnterpriseProductAdditionalInfoSaga(),
    getEnterpriseProductCountByUsersSaga(),
  ]);
}
